import React, { Component } from 'react'
import ProjectMenu from './ProjectMenu'

export default class Projects extends Component {
  render() {
    return (
      <>
        <ProjectMenu />
      </>
    )
  }
}

